import React from 'react'
import { Container } from 'react-bootstrap'
import Layout from '../components/layout'

const NotFoundPage = () => (
    <Layout
      title="404"
    >
      <Container>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn't exist... the sadness.</p>
      </Container>
    </Layout>
)

export default NotFoundPage
